Typography {
    font-family: 'Audiowide', sans-serif;
}

#title-container {
    background-color: #73ADBE;
    height: 90vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#title-container #title-text {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding: 0vw 20vw;
}

#title-container #header-logo {
    width: 10vw;
    height: 10vw;
}

#mobile-title-container {
    background-color: #73ADBE;
    height: 50vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#mobile-title-container #mobile-title-text {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding: 0vw 5vw;
}

#mobile-title-container img {
    padding-top: 3vh;
}