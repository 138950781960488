Typography {
    font-family: 'Audiowide', sans-serif;
}

#giving-back {
    background-color: white;
    height: 140vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#giving-back #giving-back-title {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding-bottom: 5vh;
}

#giving-back #divider {
    background-color: black;
    border-width: 1px;
    width: 90vw;
}

#giving-back #giving-back-grid {
    padding-top: 5vh;
}

#giving-back #giving-back-logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

#giving-back #giving-back-logos #giving-back-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#giving-back img {
    max-width: 20vw;
    max-height: 20vh;
}

#giving-back #text-img {
    max-width: 20vw;
    /* max-height: 5vh; */
}

#giving-back #giving-back-logos #giving-back-logo:hover {
    cursor: pointer;
    transform: scale(1.02);
}


#giving-back #mobile-giving-back {
    background-color: white;
    height: 145vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#giving-back #mobile-giving-back #giving-back-title {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding-bottom: 1vh;
}

#giving-back #mobile-giving-back #divider {
    background-color: black;
    border-width: 1px;
    width: 90vw;
}

#giving-back #mobile-giving-back #giving-back-grid {
    padding-top: 1vh;
}

#giving-back #mobile-giving-back #giving-back-logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

#giving-back #mobile-giving-back #giving-back-logos #giving-back-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#giving-back #mobile-giving-back img {
    max-width: 40vw;
    max-height: 15vh;
}

#giving-back #mobile-giving-back #giving-back-logos #giving-back-logo:hover {
    cursor: pointer;
    transform: scale(1.02);
}
