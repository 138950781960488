Typography {
    font-family: 'Audiowide', sans-serif;
}

#contact-us {
    background-color: #73ADBE;
    height: 80vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#contact-us #contact-us-title {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding-bottom: 5vh;
}

#contact-us #divider {
    background-color: black;
    border-width: 1px;
    width: 90vw;
}

#contact-us #et {
    padding-top: 10vh;
}

#contact-us #et img {
    border-radius: 20px;
    width: 30vw;
    max-width: 250px;
}

#contact-us #et img:hover {
    cursor: pointer;
    transform: scale(1.02);
}

#contact-us #linkedin {
    padding-top: 2vh;
    font-family: 'Audiowide', sans-serif;
    color: #000000;
}


#contact-us #mobile-contact-us {
    background-color: #73ADBE;
    height: 50vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#contact-us #mobile-contact-us #contact-us-title {
    font-family: 'Audiowide', sans-serif;
    color: black;
    padding-bottom: 1vh;
}

#contact-us #mobile-contact-us #divider {
    background-color: black;
    border-width: 1px;
    width: 90vw;
}

#contact-us #mobile-contact-us #et {
    padding-top: 5vh;
}

#contact-us #mobile-contact-us #et img {
    border-radius: 20px;
    width: 40vw;
}

#contact-us #mobile-contact-us #et img:hover {
    cursor: pointer;
    transform: scale(1.02);
}

#contact-us #mobile-contact-us #linkedin {
    padding-top: 2vh;
    font-family: 'Audiowide', sans-serif;
    color: #000000;
}

