Typography {
    font-family: 'Audiowide', sans-serif;
}

#about-us {
    background-color: #2D4C5C;
    height: 90vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#about-us #about-us-title {
    font-family: 'Audiowide', sans-serif;
    color: white;
    padding-bottom: 5vh;
}

#about-us #divider {
    background-color: white;
    border-width: 1px;
    width: 90vw;
}

#about-us #about-us-body {
    font-family: 'Audiowide', sans-serif;
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 15vh;
    padding-bottom: 15vh;
}

#about-us #mobile-about-us {
    background-color: #2D4C5C;
    height: 65vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#about-us #mobile-about-us #mobile-about-us-title {
    font-family: 'Audiowide', sans-serif;
    color: white;
    padding-bottom: 1vh;
}

#about-us #mobile-about-us #mobile-divider {
    background-color: white;
    border-width: 1px;
    width: 90vw;
}

#about-us #mobile-about-us #mobile-about-us-body {
    font-family: 'Audiowide', sans-serif;
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
}