@import url('https://fonts.googleapis.com/css?family=Audiowide');

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Audiowide', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

code {
  font-family: 'Audiowide', sans-serif;
}
