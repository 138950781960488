/* #logo {
    padding-top: 1vh;
} */

#header-logo{
    /* width: 156px; */
    height: 60px;
    /* min-width: 50%; */
    /* padding-left: 2vw; */
}

#header-logo:hover {
    cursor: pointer;
}

#header-logo-text {
    width: 25vw;
    /* height: 80px; */
    /* padding-left: 2vw; */
}

#header-logo-text:hover {
    cursor: pointer;
}

#header{
    background-color: #0c242c;
    padding-bottom: 1vw;
    padding-top: 1.5vw;
    align-items: center;
    justify-items: center;
}

#menu-item {
    padding-right: 3vw; 
}

#menu-item-circled {
    padding-right: 2vw;
}

#menu-item-text {
    color: #ffffff;
    size: 60px;
    font-family: 'Audiowide', sans-serif;
}

#menu-item-text:hover {
    color: #93D4EB;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

#menu-items-container {
    padding-bottom: 5px;
}



#header #mobile-header {
    background-color: #0c242c;
}

#header #mobile-header-logo-text {
    font-family: 'Audiowide', sans-serif;
    color: #93D4EB;
    font-size: 30px;
    font-weight: bold;
}

#mobile-header-logo {
    /* padding-left: 10%; */
    max-height: 10vh;
}

#mobile-header-logo:hover {
    cursor: pointer;
}

#hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5vw;
}

#hamburger-icon {
    transform: scale(3);
    max-width: 75%;
    color: #ffffff !important;
}



#drop-down-menu-content {
    background-color: #0c242c;
    padding-top: 14vh;
}

#drop-down-menu-item-text {
    color: #ffffff;
    font-family: 'Audiowide', sans-serif;
    size: 60px;
    padding-left: 3vw;
}

#drop-down-menu-item-text:hover {
    color: #93D4EB;
    cursor: pointer;
}

#drow-down-menu-item:hover {
    background-color: #E5E5F0;
}

.appBar {
    z-index: 1301 !important;
    background-color: white !important;
    color: black !important;
}